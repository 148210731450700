<template>
  <div class="online-order-wrapper page">
    <el-card>
      <el-calendar>
        <template
          slot="dateCell"
          slot-scope="{ date, data }"
        >
          <div
            class="calendar-custom-item"
            :class="{
              'calendar-custom-item__active': isDateInData(data.day) && !getDateInData(data.day).isClosed,
              'calendar-custom-item__closed': getDateInData(data.day).isClosed
            }"
          >
            <div class="calendar-custom-item__head">
              <span class="date">{{ getRenderDateWithCalendar(data.day) }}</span>
              <i
                v-if="isAllowedRemove && isDateInData(data.day)"
                class="remove-btn el-icon-minus"
                @click="removeWorkDate(getDateInData(data.day).id)"
              />
              <i
                v-if="isAllowedChange && !isDateInData(data.day)"
                class="add-btn el-icon-plus"
                @click="openCreateModal(data.day)"
              />
            </div>

            <div
              class="calendar-custom-item__data"
            >
              {{ getDateInData(data.day).fullName }}
            </div>
          </div>
        </template>
      </el-calendar>
    </el-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'OperatingSchedule',
  computed: {
    ...mapGetters('operatingSchedule', { getDataOperatingSchedule: 'getData' }),
    ...mapGetters('settings', ['getPermissions']),
    ...mapGetters('users', { getDataUsers: 'getData' }),
    isAllowedChange () {
      return this.getPermissions?.change?.includes('all') || this.getPermissions?.change?.includes('operatingSchedule')
    },
    isAllowedRemove () {
      return this.getPermissions?.remove?.includes('all') || this.getPermissions?.remove?.includes('operatingSchedule')
    }
  },
  async created () {
    await this.loadData()
  },
  methods: {
    ...mapActions('user', { loadDataUser: 'loadData' }),
    ...mapActions('settings', { loadDataSettings: 'loadData' }),
    ...mapActions('users', { loadDataUsers: 'loadData' }),
    ...mapActions('operatingSchedule', {
      loadDataOperatingSchedule: 'loadData',
      remove: 'remove',
      create: 'create'
    }),
    ...mapActions('modal', ['openModal']),
    async loadData () {
      // TODO: Подумать над оптимизацией загрузки данных НА ВСЕХ стр.
      await this.$bus.emit('start-loader')
      await this.loadDataUser()
      await this.loadDataSettings()
      await this.loadDataUsers()
      await this.loadDataOperatingSchedule()
      await this.$nextTick()
      await this.$bus.emit('stop-loader')
    },
    formattedCalendarDate (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    getRenderDateWithCalendar (date) {
      return moment(date).format('DD')
    },
    getDateInData (searchDate) {
      const searchDateFormatted = this.formattedCalendarDate(searchDate)

      return this.getDataOperatingSchedule.filter(({ date }) => date === searchDateFormatted)[0] || {}
    },
    isDateInData (searchDate) {
      const searchDateFormatted = this.formattedCalendarDate(searchDate)

      return this.getDataOperatingSchedule.some(({ date }) => date === searchDateFormatted)
    },
    openCreateModal (date) {
      if (!this.isAllowedChange) {
        return
      }

      const formattedDate = this.formattedCalendarDate(date)

      this.openModal({
        name: 'create-operating-schedule',
        title: `Добавить рабочую смену за ${ formattedDate }`,
        propsData: {
          selectedDate: formattedDate
        }
      })
    },
    removeWorkDate (id) {
      if (!this.isAllowedRemove) {
        return
      }

      this.remove(id)
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />